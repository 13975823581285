export enum AgentProduct {
  'null' = 'null',
  'sungop@web' = 'sungop@web',
  'winner@web' = 'winner@web',
  'futuresai@web' = 'futuresai@web',
  'futuresai@admin' = 'futuresai@admin',
  'futuresai@exchange' = 'futuresai@exchange',
  'futuresai@strategy_center' = 'futuresai@strategy_center',
  'moneycom@web' = 'moneycom@web',
  'good178@web' = 'good178@web',
  'good178@web_stock' = 'good178@web_stock',
  'hyt888@web' = 'hyt888@web',
  'hyt888@web_stock' = 'hyt888@web_stock',
  'weng888@web' = 'weng888@web',
  'weng888@web_stock' = 'weng888@web_stock',
  'asiajye@web' = 'asiajye@web',
  'asiajye@web_stock' = 'asiajye@web_stock',
  'asiajye@web_os_futures' = 'asiajye@web_os_futures',
  'winner98@web_stock' = 'winner98@web_stock',
  'winner98@web' = 'winner98@web',
  'winner98@backtest' = 'winner98@backtest',
  'winner98@crypto' = 'winner98@crypto',
  'blue168@web' = 'blue168@web',
  'sinopac@web' = 'sinopac@web',
  'bole@web_stock' = 'bole@web_stock',
  'san@web' = 'san@web',
  'winner@web_A' = 'winner@web_A',
  'winner@web_B' = 'winner@web_B',
  'winner@web_C' = 'winner@web_C',
  'morerich@web' = 'morerich@web',
  'win168@web' = 'win168@web',
  'richadam@web' = 'richadam@web',
  'richadam@web_stock' = 'richadam@web_stock',
  'ai919@web' = 'ai919@web',
  'goldbrain@web' = 'goldbrain@web',
  'goldbrain@web_stock' = 'goldbrain@web_stock',
  'lung88988@web_stock' = 'lung88988@web_stock',
  'lung88988@web' = 'lung88988@web',
  'fubon@web' = 'fubon@web',
  'kabujukuTW@web_stock' = 'kabujukuTW@web_stock',
  'ok1788@web_stock' = 'ok1788@web_stock',
  'huanan@web' = 'huanan@web',
  's178178@web' = 's178178@web',
  'mvp888@web_stock' = 'mvp888@web_stock',
  'm168168@web_stock' = 'm168168@web_stock',
  'eurex@web' = 'eurex@web',
  't3j@web' = 't3j@web',
  'big3@web' = 'big3@web',
  'big3@web_stock' = 'big3@web_stock',
  'imoney8888@web_stock' = 'imoney8888@web_stock',
  'yaya168@web_stock' = 'yaya168@web_stock',
  'south@web' = 'south@web',
  'gd1788@web' = 'gd1788@web',
  'jerry597@web_stock' = 'jerry597@web_stock',
  'gorich55688@web_stock' = 'gorich55688@web_stock',
  'daddy960@web_goodway' = 'daddy960@web_goodway',
  'daddy960@web_opkevin' = 'daddy960@web_opkevin',
  'daddy960@web_stocknerve' = 'daddy960@web_stocknerve',
  'daddy960@web_tc1688' = 'daddy960@web_tc1688',
  'daddy960@web_revive' = 'daddy960@web_revive',
  'god@web' = 'god@web',
  'david0705@web' = 'david0705@web',
  'asia71178@web' = 'asia71178@web',
  'stanli@web' = 'stanli@web',
  'datatree@web' = 'datatree@web',
  'rich888@web_stock' = 'rich888@web_stock',
  'win988@web' = 'win988@web',
  'wu5868@web_stock' = 'wu5868@web_stock',
  'pionex@web' = 'pionex@web',
  'sgx@web' = 'sgx@web',
}
