import { AxiosError } from 'axios'
import { AnyObject } from 'tsdef'
import urlcat from 'urlcat'
import { Urlu } from '~/modules/SDK/app/urlu/Urlu'
import { Admin } from '~/pages/admin/components/Admin'
import { adminNotifyPushStore, adminStore } from '~/pages/admin/components/adminStore'

export class AdminAPI extends Urlu {
  getMe = new Urlu.Query(() => {
    return this.request.axios.get<Admin.User>(`/me`).then(res => {
      if (res.status == 200) {
        adminStore.me = res.data
      }

      return res.data
    })
  })

  /** 回傳你擁有的 agents 以及對應角色 */
  getPermissions = new Urlu.Query(() => {
    return this.request.axios.get<Admin.Admin>(`/admin`).then(res => {
      if (res.status == 200) {
        adminStore.admin = res.data
      }

      return res.data
    })
  })

  getUser = new Urlu.Query(() => {
    const { selectedUserId, selectedAgent, selectedProduct } = adminStore
    return this.request.axios
      .get<Admin.NormalUser>(
        urlcat(`/users/:uid`, {
          uid: selectedUserId,
          agent: selectedAgent,
          product: selectedProduct,
        }),
      )
      .then(res => {
        if (res.status == 200) adminStore.currentUser = res.data
      })
      .catch(error => {
        errorHandling(error)
      })
  })

  getUsers = new Urlu.Query(() => {
    const {
      selectedAgent,
      selectedProduct,
      selectedProductGroup,
      page,
      pageSize,
      sortBy,
      sortOrder,
      additionSearchKey,
      subscribeAfter,
      subscribeBefore,
      expiredAfter,
      expiredBefore,
      createdAfter,
      createdBefore,
      kwName,
      kwUid,
      kwEmail,
      productStatus,
    } = adminStore

    let params: AnyObject = {
      agent: selectedAgent,
      page,
      maxResults: pageSize,
      sortBy,
      sortOrder,
    }
    if (selectedProduct === '') return

    if (selectedProductGroup) {
      params.productGroup = selectedProductGroup
    } else {
      params.product = selectedProduct
    }

    if (additionSearchKey === 'expiredAt')
      params = { ...params, expiredAfter: expiredAfter, expiredBefore }
    else if (additionSearchKey === 'startedAt') {
      params = { ...params, subscribeAfter, subscribeBefore }
    } else if (additionSearchKey === 'createdAt') {
      params = { ...params, createdAfter, createdBefore }
    } else if (additionSearchKey === 'name') {
      params = { ...params, 'name[contains]': kwName }
    } else if (additionSearchKey === 'uid') {
      params = { ...params, 'uid[contains]': kwUid }
    } else if (additionSearchKey === 'status') {
      params = { ...params, status: productStatus }
    } else if (additionSearchKey === 'email') {
      params = { ...params, 'email[contains]': kwEmail }
    }

    return this.request.axios
      .get<{ users: Admin.NormalUser[]; totalCount: number; totalPage: number; page: number }>(
        `/users`,
        {
          params,
        },
      )
      .then(res => {
        if (res.status == 200) {
          adminStore.users = res.data.users
          adminStore.page = res.data.page
          adminStore.totalPage = res.data.totalPage
          adminStore.total = res.data.totalCount
        }

        return res.data
      })
      .catch(error => {
        errorHandling(error)
      })
  })

  /**
   * history 則為 logs
   *
   * - 用途為顯示操作記錄
   */
  getUserSubscriptionHistory = new Urlu.Query(() => {
    const { selectedUserId, userScopeProduct, selectedAgent } = adminStore
    return this.request.axios
      .get<Admin.SubscriptionHistory[]>(
        urlcat(`/users/:uid/subscriptions/history`, {
          uid: selectedUserId,
          agent: selectedAgent,
          product: userScopeProduct,
        }),
      )
      .then(res => {
        if (res.status == 200) adminStore.currentUserSubscriptionHistory = res.data
      })
  })

  getAgentLogs = new Urlu.Query(() => {
    const { selectedAgent, selectedProduct, selectedProductGroup } = adminStore
    const { logQueryMode, logFrom, logTo } = adminNotifyPushStore

    if (!selectedAgent) return
    const params: Admin.LogParameters = {
      agent: selectedAgent,
      limit: 1000,
    }

    if (logQueryMode !== 'all') {
      if (selectedProductGroup) {
        params.productGroup = selectedProductGroup
      } else if (logQueryMode === 'selectedProduct') {
        params.product = selectedProduct
      }
    }

    if (logFrom) {
      params.from = logFrom
    }
    if (logTo) {
      params.to = logTo
    }

    return this.request.axios.get<Admin.LogItem[]>(urlcat(`/notify/logs`, params), {}).then(res => {
      if (res.status == 200) {
        adminNotifyPushStore.logs = res.data
      }
    })
  })

  getProductGroup = new Urlu.Query(() => {
    const { selectedAgent } = adminStore
    return this.request.axios
      .get<Admin.ProductGroup[]>(urlcat(`/productGroup`, { agent: selectedAgent }))
      .then(res => {
        if (res.status == 200) {
          adminStore.productGroup = res.data
        }
        return res.data
      })
      .catch(error => {
        errorHandling(error)
      })
  })

  createUserSubscription = new Urlu.Query((payload: { expiredAt: string; status: string }) => {
    const { selectedUserId, userScopeProduct, selectedAgent } = adminStore
    const body = { expiredAt: payload.expiredAt, status: payload.status }
    return this.request.axios
      .post<Admin.SubscriptionHistory[]>(
        urlcat(`/users/:uid/subscriptions`, {
          uid: selectedUserId,
          agent: selectedAgent,
          product: userScopeProduct,
        }),
        body,
      )
      .then(res => {
        this.getUser.fetch()
        this.getUserSubscriptionHistory.fetch()
      })
      .catch(error => {
        errorHandling(error)
      })
  })

  updateUserSubscription = new Urlu.Query((payload: { expiredAt: string; status: string }) => {
    const { selectedUserId, userScopeProduct, selectedAgent } = adminStore
    const body = { expiredAt: payload.expiredAt, status: payload.status }
    return this.request.axios
      .put<Admin.SubscriptionHistory[]>(
        urlcat(`/users/:uid/subscriptions`, {
          uid: selectedUserId,
          agent: selectedAgent,
          product: userScopeProduct,
        }),
        body,
      )
      .then(() => {
        this.getUser.fetch()
        this.getUserSubscriptionHistory.fetch()
      })
      .catch(error => {
        errorHandling(error)
      })
  })

  createUserInfo = new Urlu.Query((payload: Admin.UserInfo) => {
    const { selectedUserId } = adminStore
    return this.request.axios
      .post<Admin.SubscriptionHistory[]>(
        urlcat(`/users/:uid/info`, {
          uid: selectedUserId,
        }),
        payload,
      )
      .then(() => {
        this.getUser.fetch()
      })
  })

  updateUserInfo = new Urlu.Query((payload: Admin.UserInfo) => {
    const { selectedUserId } = adminStore
    return this.request.axios
      .put<Admin.SubscriptionHistory[]>(
        urlcat(`/users/:uid/info`, {
          uid: selectedUserId,
        }),
        payload,
      )
      .then(() => {
        this.getUser.fetch()
      })
      .catch(error => {
        errorHandling(error)
      })
  })

  updateUserName = new Urlu.Query((name: string) => {
    const { selectedUserId, userScopeProduct, selectedAgent } = adminStore
    return this.request.axios
      .put<Admin.UserName>(
        urlcat(`/users/:uid/name`, {
          uid: selectedUserId,
        }),
        { name: name },
      )
      .catch(error => {
        errorHandling(error)
      })
  })
}

const errorHandling = (error: AxiosError) => {
  let msg = `${error.response?.status} ${JSON.stringify(error.response?.data)} `
  if (error.response?.status == 401) {
    msg += '請重新登入再試試'
    adminNotifyPushStore.notificationShow = true
    adminNotifyPushStore.notificationTitle = msg
  } else {
    console.log(msg)
  }
}
